<template>
	<v-app>
		<v-main id="configurator-wrapper">
			<v-sheet>
				<div class="background">
					<div class="nav-bar">
						<router-link :to="{ name: 'configurator-start' }">
							<div class="logo"><Logo /></div>
						</router-link>
						<div class="user-button d-flex">
							<a href="/account">Einloggen</a>
							<a href="#" @click.prevent="openConfigurator" class="register">Neues Design</a>
						</div>
					</div>
					<h1>Datenschutz</h1>
					<div class="page-content">
						<div class="simple-text">
							<h2 id="i-verantwortlicher">I. verantwortlicher</h2>
							<p>
								Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der
								Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:
							</p>
							<p>
								werc GmbH<br />
								Geschäftsführer: Carl Wander<br />
								Sailerwöhr 16<br />
								94032 Passau<br />
								Deutschland
							</p>
							<p>Sitz der Gesellschaft: Passau | Amtsgericht Passau, HRB 11237</p>
							<p>
								Tel.: +49 851 98836644<br />
								E-Mail: <a href="mailto:crew@werc.de">crew@werc.de</a>
							</p>
							<h2 id="ii-allgemeines-zur-datenverarbeitung">II. allgemeines zur datenverarbeitung</h2>
							<ol>
								<li>
									<p><strong>Umfang der Verarbeitung personenbezogener Daten</strong></p>
									<p>
										Wir verarbeiten personenbezogene Daten unserer Nutzer, Interessenten bzw. Kunden grundsätzlich nur,
										soweit dies zur Bereitstellung einer funktionsfähigen Website oder zur Erbringung unserer Leistungen
										erforderlich ist. Die Verarbeitung personenbezogener Daten erfolgt regelmäßig nur nach Einwilligung
										des Betroffenen. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer
										Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch
										gesetzliche Vorschriften gestattet ist.
									</p>
								</li>
								<li>
									<p><strong>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</strong></p>
									<p>
										Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen
										Person einholen, dient Art. 6 Abs. 1 S. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als
										Rechtsgrundlage.
									</p>
									<p>
										Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
										Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 S. 1 lit. b DSGVO
										als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
										Maßnahmen erforderlich sind.
									</p>
									<p>
										Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung
										erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 S. 1 lit. c DSGVO als
										Rechtsgrundlage.
									</p>
									<p>
										Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen
										Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 S. 1 lit.
										d DSGVO als Rechtsgrundlage.
									</p>
									<p>
										Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
										Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen
										das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 S. 1 lit. f DSGVO als Rechtsgrundlage für
										die Verarbeitung.
									</p>
								</li>
								<li>
									<p>
										<strong>Datenlöschung und Speicherdauer</strong><br />
										Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck
										der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den
										europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
										Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung
										der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist
										abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen
										Vertragsabschluss oder eine Vertragserfüllung besteht.
									</p>
								</li>
							</ol>
							<h2 id="iii-bereitstellung-der-website-und-erstellung-von-logfiles">
								III. bereitstellung der website und erstellung von logfiles
							</h2>
							<ol>
								<li>
									<p>
										<strong>Beschreibung und Umfang der Datenverarbeitung</strong><br />
										Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen
										vom Computersystem des aufrufenden Rechners.
									</p>
									<p>Folgende Daten werden hierbei erhoben:</p>
									<ul>
										<li>Browsertyp/-version des Nutzers;</li>
										<li>Betriebssystem des Nutzers;</li>
										<li>IP-Adresse/Internet-Service-Provider des Nutzers;</li>
										<li>Datum/Uhrzeit des Zugriffs;</li>
										<li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt;</li>
										<li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden.</li>
									</ul>
									<p>
										Die Daten werden in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen
										mit anderen personenbezogenen Daten des Nutzers findet nicht statt.
									</p>
								</li>
								<li>
									<p><strong>Rechtsgrundlage für die Datenverarbeitung</strong></p>
									<p>
										Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 S. 1
										lit. f DSGVO.
									</p>
								</li>
								<li>
									<p>
										<strong>Zweck der Datenverarbeitung</strong><br />
										Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung
										der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für
										die Dauer der Sitzung gespeichert bleiben.
									</p>
									<p>
										Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem
										dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer
										informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem
										Zusammenhang nicht statt.
									</p>
									<p>
										In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs.
										1 S. 1 lit. f DSGVO.
									</p>
								</li>
								<li>
									<p>
										<strong>Dauer der Speicherung</strong><br />
										Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
										erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der
										Fall, wenn die jeweilige Sitzung beendet ist.
									</p>
									<p>
										Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine
										darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
										gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
									</p>
								</li>
								<li>
									<p>
										<strong>Widerspruchs- und Beseitigungsmöglichkeit</strong><br />
										Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist
										für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers
										keine Widerspruchsmöglichkeit.
									</p>
								</li>
							</ol>
							<h2 id="iv-newsletter">IV. newsletter</h2>
							<ol>
								<li>
									<p>
										<strong>Beschreibung und Umfang der Datenverarbeitung</strong><br />
										Mit Ihrer Einwilligung können Sie unseren Newsletter abonnieren, mit dem wir Sie über unsere
										aktuellen interessanten Angebote informieren. Die beworbenen Waren und Dienstleistungen sind in der
										Einwilligungserklärung benannt.
									</p>
									<p>
										Für die Anmeldung zu unserem Newsletter verwenden wir das sog. Double-Opt-In-Verfahren. Das heißt,
										dass wir Ihnen nach Ihrer Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in welcher
										wir Sie um Bestätigung bitten, dass Sie den Versand des Newsletters wünschen. Sie müssen Ihre
										Anmeldung innerhalb von 24 Stunden bestätigen, um Ihre Anmeldung freizuschalten. Darüber hinaus
										speichern wir jeweils Ihre eingesetzten IP-Adressen und Zeitpunkte der Anmeldung und Bestätigung.
										Zweck des Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen möglichen Missbrauch Ihrer
										persönlichen Daten aufklären zu können.
									</p>
									<p>
										Pflichtangabe für die Übersendung des Newsletters ist allein Ihre E-Mail-Adresse. Die Angabe
										weiterer, gesondert markierter Daten ist freiwillig und wird verwendet, um Sie persönlich ansprechen
										zu können. Nach Ihrer Bestätigung speichern wir Ihre E-Mail-Adresse zum Zweck der Zusendung des
										Newsletters.
									</p>
								</li>
								<li>
									<p>
										<strong>Rechtsgrundlage für die Datenverarbeitung</strong><br />
										Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a DSGVO.
									</p>
								</li>
								<li>
									<p>
										<strong>Zweck der Datenverarbeitung</strong><br />
										Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter zuzustellen.
									</p>
								</li>
								<li>
									<p>
										<strong>Dauer der Speicherung</strong><br />
										Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
										erforderlich sind.
									</p>
								</li>
								<li>
									<p>
										<strong>Widerrufs- und Beseitigungsmöglichkeit</strong><br />
										Ihre Einwilligung in die Übersendung des Newsletters können Sie jederzeit widerrufen und den
										Newsletter abbestellen. Den Widerruf können Sie durch Klick auf den in jeder Newsletter-E-Mail
										bereitgestellten Link oder per E-Mail an
										<a href="mailto:newsletter@werc.de">newsletter@werc.de</a> erklären.
									</p>
								</li>
								<li>
									<p>
										<strong>Versanddienstleister MailChimp</strong><br />
										Wir nutzen die Dienste von MailChimp; Anbieter ist Rocket Science Group LLC, 675 Ponce De Leon Ave
										NE, Suite 5000, Atlanta, GA 30308, USA.
									</p>
									<p>
										MailChimp ist ein Dienst, mit dem der Versand des Newsletters organisiert und analysiert werden
										kann. Sofern Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B. E-Mail-Adresse), so werden
										diese auf den Servern von MailChimp in den USA gespeichert. Wir weisen Sie darauf hin, dass wir bei
										Versand des Newsletters Ihr Nutzerverhalten auswerten. Für diese Auswertung beinhalten die
										versendeten E-Mails sogenannte Web-Beacons bzw. Tracking-Pixel, die Ein-Pixel-Bilddateien
										darstellen, die auf den Servern von MailChimp in den USA gespeichert sind. Für die Auswertungen sind
										die Web-Beacons mit Ihrer E-Mail-Adresse und einer individuellen ID verknüpft. Auch im Newsletter
										erhaltene Links enthalten diese ID. Mit den so gewonnen Daten erstellen wir ein Nutzerprofil, um
										Ihnen den Newsletter auf Ihre individuellen Interessen zuzuschneiden. Dabei erfassen wir, wann Sie
										unsere Newsletter lesen, welche Links Sie in diesen anklicken und folgern daraus Ihre persönlichen
										Interessen. Diese Daten verknüpfen wir mit von Ihnen auf unserer Website getätigten Handlungen. Wenn
										Sie keine Analyse durch MailChimp wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir
										in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Die Informationen werden
										solange gespeichert, wie Sie den Newsletter abonniert haben. Nach einer Abmeldung speichern wir die
										Daten rein statistisch und anonym. Näheres entnehmen Sie den Datenschutzbestimmungen von MailChimp
										unter:
										<a href="https://mailchimp.com/legal/terms/" title="https://mailchimp.com/legal/terms/"
											>https://mailchimp.com/legal/terms/</a
										>.
									</p>
								</li>
							</ol>
							<h2 id="v-kontaktformular-und-email-kontakt">V. kontaktformular und email-kontakt</h2>
							<ol>
								<li>
									<p>
										<strong>Beschreibung und Umfang der Datenverarbeitung</strong><br />
										Bei Ihrer Kontaktaufnahme mit uns über ein Kontaktformular auf unserer Website oder per E-Mail
										werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse und ggf. weitere von Ihnen angegeben
										Daten) an uns übermittelt und von uns gespeichert. Es erfolgt in diesem Zusammenhang keine
										Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der
										Konversation verwendet.
									</p>
								</li>
								<li>
									<p>
										<strong>Rechtsgrundlage für die Datenverarbeitung</strong><br />
										Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art.
										6 Abs. 1 S. 1 lit. a DSGVO.
									</p>
									<p>
										Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge der Kontaktaufnahme übermittelt werden,
										ist auch Art. 6 Abs. 1 S. 1 lit. f DSGVO.
									</p>
									<p>
										Zielt die Kontaktaufnahme auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage
										für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b DSGVO.
									</p>
								</li>
								<li>
									<p>
										<strong>Zweck der Datenverarbeitung</strong><br />
										Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient allein zur Bearbeitung der
										Kontaktaufnahme. Die sonstigen während des Absendevorgangs des Formulars verarbeiteten
										personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern.
									</p>
								</li>
								<li>
									<p>
										<strong>Dauer der Speicherung</strong><br />
										Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr
										erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten
										bestehen.
									</p>
								</li>
								<li>
									<p>
										<strong>Widerspruchs- und Beseitigungsmöglichkeit</strong><br />
										Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen
										Daten zu widerrufen oder der Speicherung seiner personenbezogenen Daten zu widersprechen. Alle
										personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall
										gelöscht.
									</p>
								</li>
							</ol>
							<h2 id="vi-rechte-der-betroffenen-person">VI. rechte der betroffenen person</h2>
							<p>
								Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen
								folgende Rechte gegenüber dem Verantwortlichen zu:
							</p>
							<ul>
								<li>Auskunftsrecht (Art. 15 DSGVO)</li>
								<li>Recht auf Berichtigung (Art. 16 DSGVO)</li>
								<li>Recht auf Löschung (Art. 17 DSGVO)</li>
								<li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
								<li>Recht auf Unterrichtung (Art. 19 DSGVO)</li>
								<li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
								<li>Widerspruchsrecht (Art. 21 DSGVO)</li>
								<li>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung (Art. 7 Abs. 3 DSGVO)</li>
								<li>Recht auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO)</li>
							</ul>
						</div>
					</div>
				</div>
			</v-sheet>
			<LandingFooter />
		</v-main>
	</v-app>
</template>

<script>
import Logo from '../configurator/partials/Logo'
import LandingFooter from '../homepage/partials/LandingFooter'
export default {
	name: 'PrivacyPage',
	components: { Logo, LandingFooter },
	mounted() {},
}
</script>

<style scoped lang="sass">
.nav-bar
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 70px
	border-bottom: solid 2px transparent
	display: inline-flex
	justify-content: space-between
	align-items: center
	padding: 10px 25px
	z-index: 200
	transition: all 400ms ease-in
	&.active
		background: white
		border-color: black
.bigButton
	display: inline-block
	font-weight: 700
	background: black
	border: solid 3px black
	padding: 10px 25px
	color: white
	font-size: 1.4em
.logo

	z-index: 100
	svg
		width: 100% !important
.user-button
	top: 15px
	z-index: 20
	a
		color: black
		padding: 10px 20px
		font-weight: 700
		font-size: 1.2em
		margin: 0 5px
		&.register
			background: transparent
			border: solid 2px black
			color: black
.background
	background-image: radial-gradient(rgba(0, 0, 0, 0.15) 10%, #fff 9%)
	background-position: 0 0
	background-size: 20px 20px
	background-repeat: repeat
	min-height: 100vh
	padding: 10vw 20vw
	h1
		font-size: 8em
		font-weight: 700
		letter-spacing: -0.03em
	.page-content
		background: white
		border: solid 2px black
		padding: 50px
		border-radius: 8px
		p
			font-size: 1.2em
			line-height: 2
			font-weight: 700
.wrapper
	flex-direction: column
.black-btn
	color: white
	background: black
	margin-top: 30px
.link-button
	font-size: 20px
	font-weight: 700
	text-transform: lowercase
	letter-spacing: -1px
</style>